@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.font-sans {
  font-family: "Raleway", sans-serif;
}
.font-serif {
  font-family: "Raleway", sans-serif;
}

body {
  @apply bg-lime-50 font-sans font-normal;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 10px 1px 15px rgba(0, 0, 0, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
/*body p, body div { @apply mb-4; }*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  line-height: 1.2em !important;
}
h1 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}

.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.slider-sec:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.title-heading {
  @apply bg-cover opacity-70  bg-no-repeat bg-center relative overflow-hidden w-full;
}
.title-heading:before {
  @apply bg-fixed absolute bg-neutral-900 bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-black bg-opacity-40 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
